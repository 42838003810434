import React, { useEffect, useState } from 'react';
import { validateFunctionalForm } from '../../utils';
import moment from 'moment';
import { v2_companies } from '../../actions/v2/company';
import { v2_company_vouchers } from '../../actions/v2/voucher';

const Form = (props) => {
    debugger
    const [state, setState] = useState({
        date: {
            name: 'date',
            value: props.data ? props.data.DATE : moment().format('YYYY-MM-DD'),
            isRequired: true,
            error: ''
        },
        party: {
            name: 'party',
            value: props.data ? props.data.PARTY_ID : '',
            options: [],
            isRequired: true,
            error: ''
        },
        paymentType: {
            name: 'paymentType',
            value: props.data ? props.data.PAYMENT_TYPE : '',
            options: [{ name: 'Credit', value: 'CREDIT' }, { name: 'Debit', value: 'DEBIT' }],
            isRequired: true,
            error: ''
        },
        title: {
            name: 'title',
            value: props.data ? props.data.TITLE : '',
            isRequired: true,
            error: ''
        },
        amount: {
            name: 'amount',
            value: props.data ? props.data.AMOUNT : '',
            isRequired: true,
            error: ''
        },
        voucher: {
            name: 'voucher',
            value: props.data ? props.data.VOUCHER_ID : '',
            options: [],
            isRequired: false,
            error: ''
        },
        paymentMode: {
            name: 'paymentMode',
            value: props.data ? props.data.PAYMENT_MODE : '',
            options: [{ name: 'Cash', value: 'CASH' }, { name: 'NEFT', value: 'NEFT' }, { name: 'UPI', value: 'UPI' }, { name: 'Cheque Payment', value: 'CHEQUE_PAYMENT' }],
            isRequired: true,
            error: ''
        }
    });

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: prevState[name].isRequired && !value ? 'This field is required' : ''
            }
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        debugger
        if (validateFunctionalForm(state, setState)) {
            const model = {
                date: state.date.value,
                party_id: state.party.value,
                payment_type: state.paymentType.value,
                title: state.title.value,
                amount: state.amount.value,
                voucher_id: state.voucher.value,
                payment_mode: state.paymentMode.value

            };

            if (props.data) {
                model.id = props.data.LEDGER_ID;
            }
            props.onSubmit(model);
        }
    };

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    };

    useEffect(() => {
        v2_companies().then((res) => {
            if (res.status) {
                setOptions('party', res.result);
            }
        });
    }, []);

    useEffect(() => {

        if (state.party.value) {
            v2_company_vouchers(state.party.value).then((res) => {
                if (res.status) {
                    setOptions('voucher', res.result);
                }
            });
        }
        else {
            setOptions('voucher', []);
            setState(prevState => ({
                ...prevState,
                ['voucher']: {
                    ...prevState['voucher'],
                    value: '',
                }
            }));
        }
    }, [state.party.value]);

    return (
        <React.Fragment>
            <h4 className="card-title mb-4">{props.title}</h4>
            <div id="basic-example">
                <section>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-date-input">
                                        Date <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        className={state.date.error ? 'form-control is-invalid' : 'form-control'}
                                        id="basicpill-date-input"
                                        name={state.date.name}
                                        value={state.date.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-title-input">
                                        Title <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        id="basicpill-title-input"
                                        type="text"
                                        placeholder="Title"
                                        className={state.title.error ? 'form-control is-invalid' : 'form-control'}
                                        name={state.title.name}
                                        value={state.title.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-party-input">
                                        Party <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        id="basicpill-party-input"
                                        className={state.party.error ? 'form-select is-invalid' : 'form-select'}
                                        name={state.party.name}
                                        value={state.party.value}
                                        onChange={onChange}
                                    >
                                        <option value="">Select Party</option>
                                        {state.party.options.map((item) => (
                                            <option key={item.ID} value={item.ID}>
                                                {item.COMPANY_NAME}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-voucher-input">
                                        Voucher
                                    </label>
                                    <select
                                        id="basicpill-voucher-input"
                                        className={state.voucher.error ? 'form-select is-invalid' : 'form-select'}
                                        name={state.voucher.name}
                                        value={state.voucher.value}
                                        onChange={onChange}
                                    >
                                        <option value="">Select Voucher</option>
                                        {state.voucher.options.map((item) => (
                                            <option key={item.ID} value={item.ID}>
                                                {item.INVOICE_NUMBER}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-payment-type-input">
                                        Payment Type <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        id="basicpill-payment-type-input"
                                        className={state.paymentType.error ? 'form-select is-invalid' : 'form-select'}
                                        name={state.paymentType.name}
                                        value={state.paymentType.value}
                                        onChange={onChange}
                                    >
                                        <option value="">Select Payment Type</option>
                                        {state.paymentType.options.map((item) => (
                                            <option key={item.value} value={item.value}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>



                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-amount-input">
                                        Amount <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        id="basicpill-amount-input"
                                        type="text"
                                        className={state.amount.error ? 'form-control is-invalid' : 'form-control'}
                                        name={state.amount.name}
                                        value={state.amount.value}
                                        placeholder='Amount'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-paymentMode-input">
                                        Payment Mode <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        id="basicpill-paymentMode-input"
                                        className={state.paymentMode.error ? 'form-select is-invalid' : 'form-select'}
                                        name={state.paymentMode.name}
                                        value={state.paymentMode.value}
                                        onChange={onChange}
                                    >
                                        <option value="">Select Payment Mode</option>
                                        {state.paymentMode.options.map((item) => (
                                            <option key={item.value} value={item.value}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <button type="submit" className="btn btn-success waves-effect btn-label waves-light me-2">
                                        <i className="bx bx-check-double label-icon" /> {props.buttonName}
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-danger waves-effect btn-label waves-light"
                                        onClick={() => { props.onCancel(); }}
                                    >
                                        <i className="bx bx-block label-icon" /> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </React.Fragment>
    );
};

export default Form;
