import React, { useEffect, useState } from 'react'
import Layout from '../../components/common/pageLayout'
import { toast } from 'react-toastify'
import constant from '../../constant'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v3_assign_voucher, v3_assigned_vouchers, v3_delete_voucher } from '../../actions/v3/voucherManagement'
import EntryList from '../../components/Voucher/entryList'
import { v3_add_voucher_amount_to_ledger } from '../../actions/v3/ledgerAndCashbook'
import moment from 'moment'
import { validateFunctionalForm } from '../../utils'
const List = (props) => {

    const location = useLocation();
    const { page } = location.state || {};
    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(page ? page : 1)
    const [limit, setLimit] = useState(10)
    const [searchModel, setSearchModel] = useState({
        startDate: '',
        endDate: '',
    });
    useEffect(() => {
        v3_assigned_vouchers(searchModel, currentPage, limit).then((res) => {
            if (res.status) {
                setData(res.result);
                props.history.push(constant.Authedcomponent.listVoucher.url, {
                    page: currentPage // Replace 'yourData' with the data you want to pass
                });
            }
            else {
                toast.error(res.error)
            }
        })
    }, [currentPage, limit])

    const onChangeAssignee = (record, newAssignee) => {
        v3_assign_voucher(record.ID, record.INVOICE_NUMBER, JSON.stringify(newAssignee)).then((res) => {
            if (res.status) {
                // Update the state with the new LOGIN_STATUS
                setData(prevState => ({
                    ...prevState, // Preserve other parts of the state
                    vouchers: prevState.vouchers.map(item => {
                        if (item.ID === record.ID) {
                            return {
                                ...item,
                                ASSIGNEE: newAssignee.USER_ID // Update the LOGIN_STATUS
                            };
                        }
                        return item;
                    })
                }));

                // Display a success message
                toast.success(res.result.message);
            }
        });
    };
    const onAddToLedger = (id) => {
        v3_add_voucher_amount_to_ledger(id).then((res) => {
            if (res.status) {
                toast.success(res.result.message);
            }
            else {
                toast.error(res.error.message || res.error);
            }
        })

    }

    useEffect(() => {
        setCurrentPage(1);
        v3_assigned_vouchers(searchModel, 1, limit).then((res) => {
            if (res.status) {
                setData(res.result);
                props.history.push(constant.Authedcomponent.listVoucher.url, {
                    page: currentPage,
                });
            } else {
                toast.error(res.error);
            }
        });
    }, [searchModel, limit]);
    const onDeleteVoucher = (ID) => {
        v3_delete_voucher(ID) // Replace `voucherId` with the actual voucher ID variable
            .then(() => {
                v3_assigned_vouchers(searchModel, currentPage, limit).then((res) => {
                    if (res.status) {
                        setData(res.result);
                    }
                    else {
                        toast.error(res.error)
                    }
                })
                toast.success('Your voucher has been deleted.')
            })
            .catch((error) => {
                toast.error(
                    'There was an issue deleting the voucher.'
                )
            });
    };
    return (
        <Layout
            title={'Assigned Voucher'}
        >
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <SearchOptions onSubmit={setSearchModel}
                                data={data}
                                limit={limit}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {data && <EntryList
                onChangeAssignee={onChangeAssignee}
                limit={limit}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onAddToLedger={onAddToLedger}
                onDeleteVoucher={onDeleteVoucher}

            />}

        </Layout>
    )
}

export default List

const SearchOptions = (props) => {
    const [state, setState] = useState({
        titleOption: {
            name: 'titleOption', value: '', options: [{ name: 'Party Name', value: 'partyName' },
            { name: 'Invoice Number', value: 'InvoiceNumber' },
            { name: 'RST Number', value: 'RstNumber' }], isRequired: false, error: ''
        },
        partyName: { name: 'partyName', value: '', isRequired: false, error: '' },
        InvoiceNumber: { name: 'InvoiceNumber', value: '', isRequired: false, error: '' },
        RstNumber: { name: 'RstNumber', value: '', isRequired: false, error: '' },
        startDate: { name: 'startDate', value: '', isRequired: false, error: '' },
        endDate: { name: 'endDate', value: '', isRequired: false, error: '' },
    });




    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: { ...prevState[name], value }
        }));
    };

    const handleSearch = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                startDate: state.startDate.value,
                endDate: state.endDate.value,
                title: state.titleOption.value,
                titleValue: state[state.titleOption.value]?.value
            };
            console.log(model)
            // return false
            props.onSubmit(model);
        }
    };

    const handleReset = () => {
        setState({
            titleOption: {
                name: 'titleOption', value: '', options: [{ name: 'Party Name', value: 'partyName' },
                { name: 'Invoice Number', value: 'InvoiceNumber' },
                { name: 'RST Number', value: 'RstNumber' }], isRequired: false, error: ''
            },
            partyName: { name: 'partyName', value: '', isRequired: false, error: '' },
            InvoiceNumber: { name: 'InvoiceNumber', value: '', isRequired: false, error: '' },
            RstNumber: { name: 'RstNumber', value: '', isRequired: false, error: '' },
            startDate: { name: 'startDate', value: '', isRequired: false, error: '' },
            endDate: { name: 'endDate', value: '', isRequired: false, error: '' },
        });
    };


    return (
        <React.Fragment>
            <form className="row gy-12 gx-12 align-items-center" onSubmit={handleSearch}>
                {/* Date Range */}
                <div className="col-sm-2 mb-2">
                    <label>Field Title</label>
                    <div className="input-group">
                        <select
                            id="filterTitle"
                            name="titleOption"
                            className="form-select"
                            value={state.titleOption.value}
                            onChange={handleChange}
                        >
                            <option value={''}>
                                Select
                            </option>
                            {state.titleOption.options.map((item) => {
                                return (
                                    <option value={item.value}>
                                        {item.name}
                                    </option>
                                )
                            })}

                        </select>
                    </div>
                </div>
                {state.titleOption.value && <div className="col-sm-2 mb-2">
                    <label>&nbsp;</label>
                    <div className="input-group">
                        <input
                            id="filterTitle"
                            name={state.titleOption.value}
                            className="form-control"
                            value={state[state.titleOption.value].value}
                            onChange={handleChange}
                            placeholder={'Enter ' + state.titleOption.value}
                        />

                    </div>
                </div>}
                <div className="col-sm-4 mb-2">
                    <label>Date Range</label>
                    <div className="row">
                        <div className="col">
                            <div className="input-group">
                                <input
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    className="form-control"
                                    value={state.startDate.value}
                                    onChange={handleChange}
                                    placeholder="From"
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="input-group">
                                <input
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    className="form-control"
                                    value={state.endDate.value}
                                    onChange={handleChange}
                                    placeholder="To"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Buttons */}
                <div className="col-sm-4 mb-2 d-flex align-items-center" style={{ top: '14px' }}>
                    <button
                        type="button"
                        className="btn btn-success waves-effect btn-label waves-light me-2"
                        onClick={handleSearch}
                    >
                        <i className="bx bx-search label-icon" /> Search
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-danger me-2"
                        onClick={handleReset}
                    >
                        <i className="bx bx-reset label-icon" />
                        Reset
                    </button>



                </div>
            </form>

        </React.Fragment>

    );
};
