import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Paginations from '../common/paginations';
import moment from 'moment';

const List = ({ data, setCurrentPage, limit }) => {
    const { vouchers, total, page, totalPages } = data;

    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Voucher</th>
                            <th scope="col">RST No</th>
                            <th scope="col">Invoice No</th>
                            <th scope="col">Particular (Category)</th>
                            <th scope="col">Particular (Item)</th>
                            <th scope="col">Challan Weight</th>
                            <th scope="col">Party Name</th>
                            <th scope="col">Mill</th>
                            <th scope="col">Actual Weight</th>
                            <th scope="col">Actual Rate</th>
                            <th scope="col">Invoice Rate</th>
                            <th scope="col">Amount</th>
                            <th scope="col">GST</th>
                            <th scope="col">G. Total</th>
                            <th scope="col">Source Invoice No</th>
                            <th scope="col">Source</th>
                            <th scope="col">Transporter Name</th>
                            <th scope="col">Vehicle Name</th>
                            <th scope="col">Vehicle No</th>
                            <th scope="col">Freight Charge</th>
                            <th scope="col">Weight Loss</th>
                            <th scope="col">Payment Date</th>
                            <th scope="col">Payment Amount</th>
                            <th scope="col">Debit Note Number</th>
                            <th scope="col">Debit Note</th>
                            <th scope="col">Debit Note GST</th>
                            <th scope="col">GST Pending</th>
                            <th scope="col">Balance Pending</th>
                            <th scope="col">Net Balance Pending</th>
                            <th scope="col">TDS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vouchers?.length > 0 ? (
                            vouchers.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.DATE ? moment(item.DATE).format('DD MMM, YYYY') : 'N/A'}</td>
                                    <td>{item.VOUCHER_TYPE || 'N/A'}</td>
                                    <td>{item.RST_NUMBER || 'N/A'}</td>
                                    <td>{item.INVOICE_NUMBER || 'N/A'}</td>
                                    <td>{item.PRODUCT_NAME || 'N/A'}</td>
                                    <td>{item.SUB_PRODUCT_NAME || 'N/A'}</td>
                                    <td>{item.CHALLAN_WEIGHT || 'N/A'}</td>
                                    <td>{item.PARTY_NAME || 'N/A'}</td>
                                    <td>{item.MILL || 'N/A'}</td>
                                    <td>{item.ACTUAL_WEIGHT || 'N/A'}</td>
                                    <td>{item.ACTUAL_RATE || 'N/A'}</td>
                                    <td>{item.INVOICE_RATE ? item.INVOICE_RATE.toLocaleString('en-IN') : 'N/A'}</td>
                                    <td>{item.AMOUNT ? item.AMOUNT.toLocaleString('en-IN') : 'N/A'}</td>
                                    <td>{item.GST ? item.GST.toLocaleString('en-IN') : 'N/A'}</td>
                                    <td>{item.GRAND_TOTAL ? item.GRAND_TOTAL.toLocaleString('en-IN') : 'N/A'}</td>
                                    <td>{item.SOURCE_INVOICE_NUMBER || 'N/A'}</td>
                                    <td>{item.SOURCE || 'N/A'}</td>
                                    <td>{item.TRANSPORTER_NAME || 'N/A'}</td>
                                    <td>{item.VEHICLE_NAME || 'N/A'}</td>
                                    <td>{item.VEHICLE_NUMBER || 'N/A'}</td>
                                    <td>{item.FREIGHT_CHARGE || 'N/A'}</td>
                                    <td>{item.WEIGHT_LOSS || 'N/A'}</td>
                                    <td>{item.PAYMENT_DATE ? moment(item.PAYMENT_DATE).format('DD MMM, YYYY') : 'N/A'}</td>
                                    <td>{item.PAYMENT_AMOUNT ? item.PAYMENT_AMOUNT.toLocaleString('en-IN') : 'N/A'}</td>
                                    <td>{item.DEBIT_NOTE_NUMBER || 'N/A'}</td>
                                    <td>{item.DEBIT_NOTE || 'N/A'}</td>
                                    <td>{item.DEBIT_NOTE_GST || 'N/A'}</td>
                                    <td>{item.GST_PENDING || 'N/A'}</td>
                                    <td>{item.BALANCE_PENDING || 'N/A'}</td>
                                    <td>{item.NET_BALANCE_PENDING || 'N/A'}</td>
                                    <td>{item.TDS || 'N/A'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='text-danger text-center' colSpan={28}>
                                    No records found. Please apply filters to get the correct records.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>

            <Paginations
                limit={limit}
                totalCount={total}
                range={5}
                currentPage={page}
                onPageChange={setCurrentPage}
            />
        </React.Fragment>
    );
};

export default withRouter(List);
